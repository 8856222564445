<template>
  <div class="homepage">
    <div class="first-block">
      <div class="first-text">
        <p>Nauči strani jezik</p>
        <div class="changing-text">
          <p class="phrase phrase--first">kod kuće</p>
          <p class="phrase phrase--second">uz šalicu kave</p>
          <p class="phrase phrase--third">u pauzi od faksa</p>
          <p class="phrase phrase--fourth">dok se kuha ručak</p>
          <p class="phrase phrase--fifth">dok beba spava</p>
        </div>
        <p>bez odlaska u školu!</p>
        <router-link to="/tecajevi" class="primary-button"
          >Prijavi se</router-link
        >
      </div>
      <div class="first-image">
        <img
          src="@/assets/homegirl_shadow.webp"
          alt="djevojka u fotelji"
          class="Online skola"
        />
      </div>
    </div>

    <div class="second-block">
      <div class="second-text">
        <div class="chat-bubbles">
          <p
            class="bubble"
            data-aos="zoom-in-right"
            data-aos-duration="1300"
            data-aos-delay="500"
          >
            Želim naučiti strani jezik, ali ne stignem ići u školu... &#128532;
          </p>
          <p
            class="bubble2"
            data-aos="zoom-in-left"
            data-aos-duration="1300"
            data-aos-delay="500"
          >
            Ne moraš ići u školu, upiši online tečaj!
          </p>
          <p
            class="bubble"
            data-aos="zoom-in-right"
            data-aos-duration="1300"
            data-aos-delay="600"
          >
            Ali svaki tjedan radim u različitim smjenama...
          </p>
          <p
            class="bubble2"
            data-aos="zoom-in-left"
            data-aos-duration="1300"
            data-aos-delay="600"
          >
            Na Spika tečaju sam/a biraš termine!
          </p>
          <p
            class="bubble"
            data-aos="zoom-in-right"
            data-aos-duration="1300"
            data-aos-delay="700"
          >
            A što je s knjigama i polaganjem ispita?
          </p>
          <p
            class="bubble2"
            data-aos="zoom-in-left"
            data-aos-duration="1300"
            data-aos-delay="700"
          >
            Sve potrebno za tečaj uključeno je u cijenu! &#128522;
          </p>
        </div>
      </div>
    </div>

    <div class="fourth-block">
      <div class="spika-text">
        <h4>Kako spikati?</h4>
        <ol class="spika-first">
          <li>Prijaviš se</li>
          <li>Dogovaramo raspored</li>
          <li>Spika Bag stiže na tvoju adresu</li>
        </ol>
        <h4>Što ti je potrebno?</h4>
        <ol class="spika-second">
          <li>Wi-Fi</li>
          <li>Laptop ili mobitel</li>
          <li>Slušalice</li>
        </ol>
      </div>
      <div class="spika-box">
        <img
          src="@/assets/spikabag.webp"
          alt="Online tečaj engleskog"
          loading="lazy"
        />
      </div>
    </div>

    <div class="social-block">
      <div class="social-images">
        <carousel :settings="settings" :breakpoints="breakpoints">
          <slide :key="slide">
            <div>
              <img src="@/assets/social_1.webp" alt="Social proof" />
            </div>
          </slide>
          <slide :key="slide">
            <div>
              <img src="@/assets/social_2.webp" alt="Social proof" />
            </div>
          </slide>
          <slide :key="slide">
            <div>
              <img src="@/assets/social_3.webp" alt="Social proof" />
            </div>
          </slide>
          <slide :key="slide">
            <div>
              <img src="@/assets/social_4.webp" alt="Social proof" />
            </div>
          </slide>
          <slide :key="slide">
            <div>
              <img src="@/assets/social_5.webp" alt="Social proof" />
            </div>
          </slide>
          <template #addons>
            <navigation />
          </template>
        </carousel>
      </div>
    </div>

    <div class="fifth-block" id="sign-up">
      <div class="form-block">
        <h2>Imaš pitanja?</h2>
        <p class="form-intro">(javljanje te ne obvezuje na upis tečaja)</p>
        <form @submit.prevent="sendEmail">
          <label for="fname">Ime i prezime<span>*</span></label
          ><br />
          <input
            type="text"
            id="fname"
            name="fname"
            placeholder="Unesite ime i prezime"
            v-model="fname"
          /><br />
          <label for="email">E-mail<span>*</span></label
          ><br />
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Unesite svoj e-mail"
            v-model="email"
          /><br />
          <label for="language">Jezik koji želiš učiti<span>*</span></label
          ><br />
          <select name="language" id="language" v-model="selectedLang">
            <!-- eslint-disable-next-line vue/require-v-for-key -->
            <option
              v-for="option in languages"
              :value="option.value"
              class="dropdown-text"
            >
              {{ option.label }}
            </option>
          </select>
          <label for="message">Što te zanima?<span>*</span></label
          ><br />
          <textarea
            name="message"
            id="message"
            rows="3"
            placeholder="Unesite svoj upit"
            v-model="message"
          ></textarea>
          <p class="disclaimer">* obvezna polja</p>
          <div class="form-confirmation">
            <div class="form-checkbox">
              <input id="agreement" type="checkbox" v-model="consent" />
              <label for="agreement">
                <span></span>
                <router-link to="/pravila-o-privatnosti" target="_blank"
                  >Slažem se s pravilima o privatnosti</router-link
                >
              </label>
            </div>
            <input
              type="submit"
              placeholder="Pošalji"
              value="Pošalji"
              :disabled="!enabledInput"
            />
          </div>
        </form>
      </div>
      <div class="fifth-image">
        <img
          class="bath--desktop"
          src="@/assets/bathgirl.webp"
          alt="Škola stranih jezika Osijek"
          loading="lazy"
        />
      </div>
    </div>

    <div class="sixth-block">
      <div class="hand-image left">
        <img
          src="@/assets/righthand.webp"
          alt="Online test engleski"
          loading="lazy"
        />
      </div>
      <div class="apply-section">
        <div class="apply-text">
          <p>Nemoj otići praznih ruku...</p>
          <p>Provjeri svoje znanje besplatno!</p>
        </div>
        <router-link to="/online-test" class="primary-button"
          >Online test</router-link
        >
      </div>
      <div class="hand-image right">
        <img src="@/assets/righthand.webp" alt="Online test" loading="lazy" />
      </div>
      <div class="hand-image center">
        <img
          src="@/assets/righthand.webp"
          alt="Online test engleski jezik"
          loading="lazy"
        />
      </div>
    </div>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import AOS from "aos";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";

AOS.init();

export default {
  name: "Home",
  data() {
    return {
      fname: "",
      email: "",
      language: "",
      message: "",
      consent: "",
      selectedLang: null,
      languages: [
        {
          label: "Engleski",
          value: "engleski",
        },
        {
          label: "Njemački",
          value: "njemački",
        },
      ],
    };
  },
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  setup() {
    return {
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        wrapAround: "true",
        autoplay: "5000",
        pauseAutoplayOnHover: "true",
      },
      breakpoints: {
        700: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 2,
          snapAlign: "start",
        },
      },
    };
  },
  computed: {
    enabledInput() {
      return (
        this.fname &&
        this.email &&
        this.selectedLang &&
        this.message &&
        this.consent
      );
    },
  },
  methods: {
    sendEmail(e) {
      try {
        emailjs.sendForm(
          "service_le975zf",
          "template_9ap4a3q",
          e.target,
          "user_PJQBfHyG39g5O2oMexNAM",
          {
            fname: this.fname,
            email: this.email,
            language: this.language,
            message: this.message,
          }
        );
        Swal.fire({
          icon: "success",
          title: "Uspješno poslano!",
          text: "Javit ćemo ti se uskoro - provjeri i neželjenu poštu :)",
          confirmButtonText: "U redu",
        });
      } catch (error) {
        console.log({ error });
      }
      // Reset form field
      this.fname = "";
      this.email = "";
      this.language = "";
      this.message = "";
      this.consent = "";
    },
  },
  created() {
    this.selectedLang = this.languages[0].value;
  },
};
</script>
