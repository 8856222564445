<template>
  <div class="online-exam">
    <div class="block-first exam">
      <div class="exam-section">
        <div class="exam-choice">
          <div class="exam-text">
            <h3 class="uppercase-text">Upute za rješavanje</h3>
            <ol class="exam-directions">
              <li>Izaberi jezik koji želiš</li>
              <li>Označi u svakom zadatku 1 točan odgovor</li>
              <li>
                Da... test je dug, ali ne bi bilo fer procijeniti tvoje znanje
                kroz 10 pitanja, zar ne?
              </li>
              <li>
                Cijeli test pokriva A1-C1 razine znanja. Kada ti zadaci postanu
                preteški odaberi "Pošalji" na kraju testa (ne moraju sva pitanja
                biti riješena)
              </li>
              <li>Rezultate ćeš dobiti na svoj mail (provjeri i spam)</li>
            </ol>
          </div>
          <div class="exam-languages">
            <button id="open-german-exam" @click="openExam('german', 'exam')">
              Njemački
            </button>
            <button id="open-english-exam" @click="openExam('english', 'exam')">
              Engleski
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="exams" ref="exam">
      <div class="exam-english" v-if="language == 'english'">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLScPNUOgBDe58Rtr1RexjxlBaU4C4DmvsHuOIR7lxUgDHFcGEA/viewform?embedded=true"
          width="700"
          height="1400"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          >Učitavanje…</iframe
        >
      </div>
      <div class="exam-german" v-if="language == 'german'">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSffbPP8cEDVNirlQTKOpQnzyC3nOcFMli6GrV_i_jsurbQ9yA/viewform?embedded=true"
          width="700"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          >Učitavanje…</iframe
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Online test",
  data() {
    return {
      language: "german",
    };
  },
  methods: {
    openExam: function (lang, div) {
      var element = this.$refs[div];
      var top = element.offsetTop;

      this.language = lang;
      window.scrollTo({
        top: top,
        behavior: "smooth"
      });
    },
  },
};
</script>
