<template>
  <app-header />
  <router-view />
  <app-footer />
</template>

<style lang="scss">
@import "./styles/_styles.sass";
</style>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    "app-header": Header,
    "app-footer": Footer,
  }
};
</script>
