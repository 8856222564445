<template>
  <div class="introduction block-first">
    <div class="introduction__title">
      <h2 class="language">ENGLESKI ili NJEMAČKI?</h2>
    </div>
    <div class="introduction__text">
      <p>Izaberi način učenja koji ti najbolje odgovara!</p>
    </div>
    <div class="introduction__arrow" @click="scrollDown('courses')">
      <div>
        <img src="@/assets/arrow_down.svg" alt="strelica" />
      </div>
    </div>
  </div>
  <div class="courses" id="course-section" ref="courses">
    <div class="section section__first">
      <h3 class="section-title section-title--mobile">1 na 1</h3>
      <div class="section-text">
        <h3 class="section-title section-title--desktop">1 na 1</h3>
        <p>
          Uči gdje želiš, bez odlaska u školu, uz raspored koji ti odgovara i
          profesora koji te vodi i motivira! Sve što ti treba su laptop,
          slušalice i dobra internetska veza. Ne moraš čak uključiti ni kameru!
        </p>
        <div class="course-apply">
          <div class="section-text__important">
            <p>KLASIČNI TEČAJ <span>(30 x 45 min)</span></p>
            <p>+ testiranje, knjige, ispit, diploma</p>
          </div>
          <div class="course-price">
            <div>
              <p>497€</p>
              <p class="payments">(ili na rate 8 x 65 = 520€)</p>
            </div>
          </div>
        </div>
        <div class="course-apply second">
          <div class="section-text__important">
            <p>INTENZIVNI TEČAJ <span>(20 x 45 min)</span></p>
            <p>+ testiranje, knjige, ispit, diploma</p>
          </div>
          <div class="course-price">
            <div>
              <p>377€</p>
              <p class="payments">(ili na rate 6 x 65 = 390€)</p>
            </div>
          </div>
        </div>
        <p class="disclaimer disclaimer--courses">
          *intenzivni tečaj - isto gradivo se obrađuje u kraćem vremenskom
          periodu (vježbe i zadaci namijenjeni su za rad poslije sata)
        </p>
        <router-link to="/prijava" class="primary-button"
          >Prijavi se</router-link
        >
      </div>
      <div class="section-image">
        <img
          src="@/assets/oneonone.webp"
          alt="Učenje engleskog jezika kod kuće"
          loading="lazy"
        />
      </div>
    </div>

    <div class="section section__second">
      <h3 class="section-title section-title--mobile">U paru</h3>
      <div class="section-image">
        <img
          src="@/assets/pair.webp"
          alt="Učenje engleskog jezika"
          loading="lazy"
        />
      </div>
      <div class="section-text">
        <h3 class="section-title section-title--desktop">U paru</h3>
        <p>
          Muž, brat ili frendica žele naučiti isti jezik kao ti? Podijeli račun
          i uz dodatnu podršku svog para propričaj jezik koji želiš! Jedini
          uvjet je da ste na istoj razini znanja. U dvoje je slađe jer uvijek
          možeš prepisati zadaću (šalimo se naravno)!
        </p>
        <div class="course-apply">
          <div class="section-text__important">
            <p>30 x 45 min + testiranje znanja, knjige, ispit, diploma</p>
          </div>
          <div class="course-price">
            <div>
              <p>po osobi: </p>
              <p class="payments"> <b>297€</b> (ili na rate 5 x 63€ = 315€)</p>
            </div>
          </div>
        </div>
        <router-link to="/prijava" class="primary-button"
          >Prijavi se</router-link
        >
      </div>
    </div>

    <div class="section section__third">
      <h3 class="section-title section-title--mobile">
        Firmaši – grupni tečaj
      </h3>
      <div class="section-text">
        <h3 class="section-title section-title--desktop">
          Firmaši – grupni tečaj
        </h3>
        <p>
          Tvoja ekipa s posla najjača je, a bila bi još jača da joj idu strani
          jezici. Obrišite prevoditelj s desktopa i impresionirajte šeficu! Od
          poslovnog engleskog do IT njemačkog - sve je lako uz ekipu. #pišinafirmu
        </p>
        <div class="course-apply course-apply--company">
          <p class="section-text__important">
            50 x 45 min + testiranje znanja, knjige, ispit, diploma
          </p>
          <p class="course-price">cijena na upit</p>
        </div>
        <router-link to="/prijava" class="primary-button"
          >Prijavi se</router-link
        >
      </div>
      <div class="section-image section-image--pair">
        <img
          src="@/assets/company.webp"
          alt="Učenje njemačkog jezika kod kuće"
          loading="lazy"
        />
      </div>
    </div>

    <div class="section section__fourth">
      <h3 class="section-title section-title--mobile">
        Samo Spika - konverzacija
      </h3>
      <div class="section-image">
        <img src="@/assets/hellogirl.webp" alt="Učenje njemačkog jezika" />
      </div>
      <div class="section-text">
        <h3 class="section-title section-title--desktop">
          Samo Spika - konverzacija
        </h3>
        <p>
          Serije gledaš bez titlova, ali kada trebaš pričati uhvati te panika?
          Uz Spika profesore pričaj o temama koje su tebi bitne. Upozoravat će
          te na pogreške koje praviš i ispravljati tvoj izgovor. Vrijeme će ti
          proletiti kao da si s frendom na kavi!
        </p>
        <div class="course-apply">
          <p class="section-text__important">
            15 x 45 min + testiranje znanja
          </p>
          <div class="course-price">
            <div>
              <p>215€</p>
              <p class="payments">(ili na rate 5 x 45€ = 225€)</p>
            </div>
          </div>
        </div>
        <router-link to="/prijava" class="primary-button"
          >Prijavi se</router-link
        >
      </div>
    </div>

    <div class="section section__fifth">
      <div class="section-text">
        <h3 class="section-title">ŠALJI SNIMKU</h3>
        <p>
          Uz Spiku nećeš propustiti ni jedan sat! Kada nisi u mogućnosti biti na
          satu, šaljemo ti snimku sata koju možeš pogledati kada želiš! Nikada
          ne propuštaš sat i svoj cilj uvijek ostvaruješ na vrijeme!
        </p>
      </div>
      <div class="section-image">
        <img src="@/assets/laptop.webp" alt="Video tečaj" loading="lazy" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tecajevi",
  methods: {
    scrollDown: function (div) {
      console.log("scroll");
      var element = this.$refs[div];
      var top = element.offsetTop;
      window.scrollTo({
        top: top,
        behavior: "smooth",
      });
    },
  },
};
</script>
