<template>
  <div class="application-page">
    <div class="form-block">
      <h2>Prijavi se</h2>
      <br />
      <form @submit.prevent="sendEmail">
        <div class="form-left">
          <label for="fname">Ime i prezime<span>*</span></label
          ><br />
          <input
            type="text"
            id="fname"
            name="fname"
            placeholder="Unesite ime i prezime"
            v-model="fname"
          /><br />
          <label for="email">E-mail<span>*</span></label
          ><br />
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Unesite svoj e-mail"
            v-model="email"
          /><br />
          <label for="phone">Telefon<span>*</span></label
          ><br />
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder="Unesite broj telefona"
            v-model="phone"
          /><br />
          <label for="language">Jezik koji želiš učiti<span>*</span></label
          ><br />
          <select name="language" id="language" v-model="selectedLang">
            <!-- eslint-disable-next-line vue/require-v-for-key -->
            <option
              v-for="option in languages"
              :value="option.value"
              class="dropdown-text"
            >
              {{ option.label }}
            </option>
          </select>
          <label for="courseType">Vrsta tečaja<span>*</span></label
          ><br />
          <select name="courseType" id="courseType" v-model="selectedCourse">
            <!-- eslint-disable-next-line vue/require-v-for-key -->
            <option
              v-for="option in courses"
              :value="option.value"
              class="dropdown-text"
            >
              {{ option.label }}
            </option>
          </select>
        </div>

        <div class="form-right">
          <div class="form-days">
            <p class="mt-2">Dani u tjednu</p>
            <div class="day monday">
              <input
                id="ponedjeljak"
                type="checkbox"
                name="days"
                value="pon"
                v-model="days"
              />
              <label for="ponedjeljak">
                <span></span>
                Ponedjeljak
              </label>
            </div>
            <div class="day tuesday">
              <input id="utorak" type="checkbox" name="days" value="uto" v-model="days" />
              <label for="utorak">
                <span></span>
                Utorak
              </label>
            </div>
            <div class="day wednesday">
              <input id="srijeda" type="checkbox" name="days" value="sri" v-model="days" />
              <label for="srijeda">
                <span></span>
                Srijeda
              </label>
            </div>
            <div class="day thursday">
              <input id="četvrtak" type="checkbox" name="days" value="cet" v-model="days" />
              <label for="četvrtak">
                <span></span>
                Četvrtak
              </label>
            </div>
            <div class="day friday">
              <input id="petak" type="checkbox" name="days" value="pet" v-model="days" />
              <label for="petak">
                <span></span>
                Petak
              </label>
            </div>
            <div class="day fix"></div>
          </div>

          <div class="form-part-day">
            <label for="courseType">Termini</label>
            <br />
            <select name="partOfDay" id="partOfDay" v-model="partOfDay">
              <option value="" disabled selected>Odaberi doba dana</option>
              <!-- eslint-disable-next-line vue/require-v-for-key -->
              <option
                v-for="option in dayParts"
                :value="option.value"
                class="dropdown-text"
              >
                {{ option.label }}
              </option>
            </select>
          </div>

          <div class="form-hours" v-if="partOfDay">
            <p class="form-title">Vrijeme</p>

            <div class="morning-hours" v-if="partOfDay === 'Prijepodne' ">
              <div class="hours eight">
                <input
                  id="eight"
                  type="checkbox"
                  name="selectedTimes"
                  :value="times[0].value"
                  v-model="selectedTimes"
                />
                <label for="eight">
                  <span></span>
                  {{ times[0].label }}
                </label>
              </div>
              <div class="hours nine">
                <input
                  id="nine"
                  type="checkbox"
                  name="selectedTimes"
                  :value="times[1].value"
                  v-model="selectedTimes"
                />
                <label for="nine">
                  <span></span>
                  {{ times[1].label }}
                </label>
              </div>
              <div class="hours ten">
                <input 
                  id="ten" 
                  type="checkbox" 
                  name="selectedTimes" 
                  :value="times[2].value"
                  v-model="selectedTimes" 
                />
                <label for="ten">
                  <span></span>
                  {{ times[2].label }}
                </label>
              </div>
              <div class="hours eleven">
                <input 
                  id="eleven" 
                  type="checkbox" 
                  name="selectedTimes" 
                  :value="times[3].value"
                  v-model="selectedTimes" 
                />
                <label for="eleven">
                  <span></span>
                  {{ times[3].label }}
                </label>
              </div>
            </div>

            <div class="afternoon-hours" v-if="partOfDay === 'Poslijepodne'">
              <div class="hours twelve">
                <input
                  id="twelve"
                  type="checkbox"
                  name="selectedTimes"
                  :value="times[4].value"
                  v-model="selectedTimes"
                />
                <label for="twelve">
                  <span></span>
                  {{ times[4].label }}
                </label>
              </div>
              <div class="hours thirteen">
                <input
                  id="thirteen"
                  type="checkbox"
                  name="selectedTimes"
                  :value="times[5].value"
                  v-model="selectedTimes"
                />
                <label for="thirteen">
                  <span></span>
                  {{ times[5].label }}
                </label>
              </div>
              <div class="hours forteen">
                <input 
                  id="forteen" 
                  type="checkbox" 
                  name="selectedTimes" 
                  :value="times[6].value"
                  v-model="selectedTimes" 
                />
                <label for="forteen">
                  <span></span>
                  {{ times[6].label }}
                </label>
              </div>
              <div class="hours fifteen">
                <input 
                  id="fifteen" 
                  type="checkbox" 
                  name="selectedTimes" 
                  :value="times[7].value"
                  v-model="selectedTimes" 
                />
                <label for="fifteen">
                  <span></span>
                  {{ times[7].label }}
                </label>
              </div>
            </div>
            
            <div class="evening-hours" v-if="partOfDay === 'Navečer'">
              <div class="hours sixteen">
                <input
                  id="sixteen"
                  type="checkbox"
                  name="selectedTimes"
                  :value="times[8].value"
                  v-model="selectedTimes"
                />
                <label for="sixteen">
                  <span></span>
                  {{ times[8].label }}
                </label>
              </div>
              <div class="hours seventeen">
                <input
                  id="seventeen"
                  type="checkbox"
                  name="selectedTimes"
                  :value="times[9].value"
                  v-model="selectedTimes"
                />
                <label for="seventeen">
                  <span></span>
                  {{ times[9].label }}
                </label>
              </div>
              <div class="hours eighteen">
                <input 
                  id="eighteen" 
                  type="checkbox" 
                  name="selectedTimes" 
                  :value="times[10].value"
                  v-model="selectedTimes" 
                />
                <label for="eighteen">
                  <span></span>
                  {{ times[10].label }}
                </label>
              </div>
              <div class="hours nineteen">
                <input 
                  id="nineteen" 
                  type="checkbox" 
                  name="selectedTimes" 
                  :value="times[11].value"
                  v-model="selectedTimes" 
                />
                <label for="nineteen">
                  <span></span>
                  {{ times[11].label }}
                </label>
              </div>
            </div>
          </div>

          <div class="form-rest">
            <label for="message" class="form-title">Dodatna pitanja?</label>
            <textarea
              name="message"
              id="message"
              rows="3"
              placeholder="Opiši nam svoju situaciju (radno i slobodno vrijeme, kada želiš imati tečaj i sl.)"
              v-model="message"
            ></textarea>
            <p class="disclaimer">* obvezna polja</p>
            <div class="form-confirmation">
              <div class="form-checkbox">
                <input id="agreement" type="checkbox" v-model="consent" />
                <label for="agreement">
                  <span></span>
                  <router-link to="/pravila-o-privatnosti" target="_blank"
                    >Slažem se s pravilima o privatnosti</router-link
                  >
                </label>
              </div>
              <input
                type="submit"
                placeholder="Pošalji"
                value="Pošalji"
                :disabled="!enabledInput"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
import Swal from "sweetalert2";

export default {
  name: "Prijava",
  data() {
    return {
      fname: "",
      email: "",
      language: "",
      courseType: "",
      time: "",
      message: "",
      phone: "",
      consent: "",
      selectedLang: null,
      selectedCourse: null,
      selectedTimes: [],
      partOfDay: "",
      days: [],
      languages: [
        {
          label: "Engleski",
          value: "Engleski",
        },
        {
          label: "Njemački",
          value: "Njemački",
        },
      ],
      courses: [
        {
          label: "1na1 - individualni tečaj (klasični - 30h)",
          value: "1na1 - individualni tečaj (klasični - 30h)",
        },
        {
          label: "1na1 - individualni tečaj (intenzivni - 20h)",
          value: "1na1 - individualni tečaj (intenzivni - 20h)",
        },
        {
          label: "U paru",
          value: "U paru",
        },
        {
          label: "Grupni tečaj",
          value: "Grupni tečaj",
        },
        {
          label: "Samo Spika - konverzacijski tečaj",
          value: "Samo Spika - konverzacijski tečaj",
        },
      ],
      dayParts: [
        {
          label: "Prijepodne",
          value: "Prijepodne",
        },
        {
          label: "Poslijepodne",
          value: "Poslijepodne",
        },
        {
          label: "Navečer",
          value: "Navečer",
        },
      ],
      times: [
        {
          label: "08:00 - 09:00",
          value: "08:00 - 09:00",
        },
        {
          label: "09:00 - 10:00",
          value: "09:00 - 10:00",
        },
        {
          label: "10:00 - 11:00",
          value: "10:00 - 11:00",
        },
        {
          label: "11:00 - 12:00",
          value: "11:00 - 12:00",
        },
        {
          label: "12:00 - 13:00",
          value: "12:00 - 13:00",
        },
        {
          label: "13:00 - 14:00",
          value: "13:00 - 14:00",
        },
        {
          label: "14:00 - 15:00",
          value: "14:00 - 15:00",
        },
        {
          label: "15:00 - 16:00",
          value: "15:00 - 16:00",
        },
        {
          label: "16:00 - 17:00",
          value: "16:00 - 17:00",
        },
        {
          label: "17:00 - 18:00",
          value: "17:00 - 18:00",
        },
        {
          label: "18:00 - 19:00",
          value: "18:00 - 19:00",
        },
        {
          label: "19:00 - 20:00",
          value: "19:00 - 20:00",
        },
      ],
    };
  },
  computed: {
    enabledInput() {
      return (
        this.fname &&
        this.email &&
        this.phone &&
        this.selectedLang &&
        this.selectedCourse &&
        this.consent
      );
    },
  },
  watch: {
    partOfDay(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectedTimes = [];
      }
    },
  },
  methods: {
    sendEmail(e) {
      try {
        emailjs.sendForm(
          "service_le975zf",
          "template_prijava",
          e.target,
          "user_PJQBfHyG39g5O2oMexNAM",
          {
            days: this.days,
            fname: this.fname,
            email: this.email,
            language: this.language,
            phone: this.phone,
            courseType: this.courseType,
            selectedTimes: this.selectedTimes,
            message: this.message,
          }
        );
        Swal.fire({
          icon: "success",
          title: "Uspješno poslano!",
          text: "Javit ćemo ti se uskoro - provjeri i neželjenu poštu :)",
          confirmButtonText: "U redu",
        });
        console.log(this.days, this.selectedTimes)
      } catch (error) {
        console.log({ error });
      }
      // Reset form field
      setTimeout(() => {
        this.fname = "";
        this.email = "";
        this.language = "";
        this.courseType = "";
        this.time = "";
        this.message = "";
        this.phone = "";
        this.consent = "";
        this.days = [];
        this.partOfDay = "";
        this.selectedTimes = [];
      }, 1000); 
    },
  },
  created() {
    this.selectedLang = this.languages[0].value;
    this.selectedCourse = this.courses[0].value;
  },
};
</script>
