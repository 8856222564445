<template>
  <header>
    <div class="logo">
      <router-link to="/" class="home-link">
        <h1>spika.</h1>
      </router-link>
    </div>
    <div class="navbar">
      <ul>
        <li>
          <router-link to="/tecajevi">Tečajevi</router-link>
        </li>
        <!--
        <li>
          <router-link to="/prijevod">Prijevod</router-link>
        </li>
        -->
        <li>
          <router-link to="/online-test">Online test</router-link>
        </li>
        <li>
          <router-link to="/o-nama">O nama</router-link>
        </li>
        <!--
        <li>
          <router-link to="/blog">Blog</router-link>
        </li>
        -->
      </ul>
    </div>
    <div
      class="menu-icon"
      id="menuToggle"
      @click="toggleMenu"
      :class="{ active: isActive }"
    >
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
    </div>
    <div class="navbar-mobile" :class="{ active: isActive }">
      <ul>
        <li>
          <router-link to="/" @click="toggleMenu"
            >Početna</router-link
          >
        </li>
        <!--
        <li>
          <router-link to="/prijevod" @click="toggleMenu"
            >Prijevod</router-link
          >
        </li>
        -->
        <li>
          <router-link to="/tecajevi" @click="toggleMenu"
            >Tečajevi</router-link
          >
        </li>
        <li>
          <router-link to="/online-test" @click="toggleMenu"
            >Online test</router-link
          >
        </li>
        <li>
          <router-link to="/o-nama" @click="toggleMenu"
            >O nama</router-link
          >
        </li>
        <!--
        <li>
          <router-link to="/blog" @click="toggleMenu"
            >Blog</router-link
          >
        </li>
        -->
      </ul>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isActive = !this.isActive;
      document.body.classList.toggle('mobile-menu-open');
    }
  }
};
</script>
