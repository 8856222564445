import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Test from '../views/OnlineTest.vue';
import Prijava from '../views/Prijava.vue';
import Tecajevi from '../views/Tecajevi.vue';
// import Prijevod from '../views/Prijevod.vue';
// import Blogovi from '../views/Blogovi.vue';

// import ZastoNeIci from '../blogs/ZastoNeIciNaOnlineTecaj.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/tecajevi',
    name: 'Tecajevi',
    component: Tecajevi,
  },
  /* 
  {
    path: '/prijevod',
    name: 'Prijevod',
    component: Prijevod,
  }, */
  {
    path: '/online-test',
    name: 'Online test',
    component: Test,
  },
  {
    path: '/o-nama',
    name: 'O nama',
    component: () => import('../views/ONama.vue'),
  },
  {
    path: '/prijava',
    name: 'Prijava',
    component: Prijava,
  },
  /* {
    path: '/blog',
    name: 'Blogovi',
    component: Blogovi,
  }, */
  {
    path: '/pravila-o-privatnosti',
    name: 'Pravila',
    component: () => import('../views/PravilaPrivatnosti.vue'),
  },
  /* {
    path: '/blog/zasto-ne-ici-na-online-tecaj',
    name: 'ZastoNeIci',
    component: ZastoNeIci,
  }, */
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return {
      top: 0,
      behavior: 'smooth',
    };
  },
});

export default router;
